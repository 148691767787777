import React from "react";
import Search from "../components/Home/Search";
import { Helmet } from 'react-helmet';

const Courses = () => {
  return (
    <div className="min_height ">
         <Helmet>
        <title>Study Abroad Courses: Find Top Programs with Edulley</title>
        <meta name="description" content="Discover top study abroad courses at Edulley. Explore diverse programs and find the perfect fit for your academic journey. Start your adventure today!" />
        <link rel="canonical" href="https://www.edulley.com/courses" />
      </Helmet>
      <Search />
    </div>
  );
};

export default Courses;
