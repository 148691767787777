import React from "react";
import scholar1 from "../../assets/scholarship1.png";
import list from "../../assets/list.svg";
import cherons from "../../assets/chevrons-right.png";
import SOPCard from "./sopcard";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

const SOP = () => {
  const navigate=useNavigate();
  const user = JSON.parse(localStorage.getItem("_u"));
  const loggedIn = !!user?.token;
  return (
    <>
     <Helmet>
        <title>Study Abroad: SOP Writing Services & College Admission</title>
        <meta name="description" content="Enhance your study abroad application with professional SOP writing services. Get expert help crafting a standout Statement of Purpose for college admission success." />
        <link rel="canonical" href="https://www.edulley.com/sop" />
      </Helmet>
      <div className="container-fluid sop_container">
        <div className="ielts_content container">
          <h1 style={{color:"#fff",fontFamily:"Gilroy-Bold"}} >Statement of Purpose</h1>
          <h2 style={{color:"#fff",fontFamily:"Gilroy-Medium"}}>All you need to know!</h2>
         <button className="explore-button mt-3 bg-black pull-right" style={{fontFamily:"Gilroy-Medium"}} onClick={() =>{
  if(!loggedIn) {
    navigate("/");
    return;
  };
window.open('https://www.uc.edu/content/dam/refresh/learning-commons-62/awc/awc-grad/sample-statement-handout-spring-2021.pdf', '_blank')
         } }>
          {' Download SOP'}
          </button>
        </div>
      </div>
      <div className="container py-4 course_container">
        <h3 className="mb-3" style={{fontFamily:"Gilroy-Bold"}}>Statement of Purpose</h3> 
        <div className="d-flex  justify-content-between inner_course mt-0">
          <div className="row">
            <div className="col-md-9">
              <div>
                <SOPCard />
              </div>
            </div>
            <div className="col-md-3">
              <div className="right_scholar exam-bar">
                  <div className="d-flex align-items-center gap-3">
                    <img  style={{width:"2rem",height:"2rem"}} src={list} alt="" />
                    <h4 className="text-pink  mb-0 " style={{fontFamily:"Gilroy-Bold"}}>
                      Table of Contents
                    </h4>
                  </div>
                <div className="s_img_card py-3">
                  <ul>
                    <li className="text-pink" style={{fontFamily:"Gilroy-Medium"}}>What is SOP</li>
                    <li style={{fontFamily:"Gilroy-Medium"}}>Why is SOP important</li>
                    <li style={{fontFamily:"Gilroy-Medium"}}>SOP Format</li>
                    <li style={{fontFamily:"Gilroy-Medium"}}>Hoe to write SOP</li>
                    <li style={{fontFamily:"Gilroy-Medium"}}>What do college look for in an SOP</li>
                    <li style={{fontFamily:"Gilroy-Medium"}}>Tips for writing SOP</li>
                    <li style={{fontFamily:"Gilroy-Medium"}}>Common mistakes to avoid in SOP</li>
                    <li style={{fontFamily:"Gilroy-Medium"}}>Importance of SOP</li>
                    <li style={{fontFamily:"Gilroy-Medium"}}>Word limit in SOP</li>
                    <li style={{fontFamily:"Gilroy-Medium"}}>Samples for SOP</li>
                 
                  </ul>
                </div>
                  {/* <div className="d-flex align-items-center gap-3">
                    <img  style={{width:"2rem",height:"2rem"}}  src={list} alt="" />
                    <h4 className="text-pink  mb-0 " style={{fontFamily:"Gilroy-Bold"}}>
                      Sample of SOP
                    </h4>
                  </div>
                <div className="s_img_card py-3">
                  <ul>
                    <li className="text-green" style={{fontFamily:"Gilroy-Medium"}}>Sample SOP for MBA</li>
                    <li className="text-green" style={{fontFamily:"Gilroy-Medium"}}>Sample SOP for Masters</li>
                    <li className="text-green" style={{fontFamily:"Gilroy-Medium"}}>Sample SOP for Bachelors</li>
                    <li className="text-green" style={{fontFamily:"Gilroy-Medium"}}>Sample SOP for other courses</li>
                  
                  </ul>
                </div> */}
                <div className="s_img_card side-bar-img text-center">
                  <img src={scholar1} alt="" />
                  <p className="mt-2" style={{fontFamily:"Gilroy-Bold"}}>
                    Let’s look at the scholarships available for you
                  </p>
                  <button onClick={()=>navigate('/scholarship')} className="explore-button py-2 fw-light mt-2" style={{fontFamily:"Gilroy-Medium"}}>
                    Explore All Scholarship
                  </button>
                  <p className="fw-bold my-2" style={{fontFamily:"Gilroy-Bold"}}>OR</p>
                  <a href="https://wa.me/message/SMDIYPHGQFQRC1" target="_blank"
                    
                    style={{ color: "#ff5573", cursor: "pointer" ,fontFamily:"Gilroy-Medium"}}
                  >
                    Chat with us 
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SOP;
