import React from "react";
import Universties from "../components/Home/Universties";
import { Helmet } from 'react-helmet';

const UniversitesPage = () => {
  return (
    <div>
        <Helmet>
        <title>Study Abroad: Best Universities & Courses Available</title>
        <meta name="description" content="Explore top universities and courses for studying abroad with Edulley. Discover your ideal program and university to enhance your international education journey." />
        <link rel="canonical" href="https://www.edulley.com/institutions" />
      </Helmet>
      <Universties />
    </div>
  );
};

export default UniversitesPage;
